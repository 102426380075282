// Styles and overrides for all elements out of the lightcase scope
.lightcase-open {

  body {
    // For mobile (touch) devices
    .lightcase-isMobileDevice & {
      max-width: 100%;
      max-height: 100%;
      overflow: hidden;
    }

    @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
      padding: 55px 0 70px 0;

      & > *:not([id*='lightcase-']) {
        position: fixed !important;
        top: -9999px !important;

        width: 0 !important;
        height: 0 !important;

        overflow: hidden !important;
      }
    }
  }

}